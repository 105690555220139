<script>
import { defineComponent } from "vue";
import { mapActions } from "vuex";

import "@lottiefiles/lottie-player";
import securityAnimation from "@/assets/animations/lottie/security-colored.json";

import AuthLayout from "../layouts/AuthLayout";

export default defineComponent({
  name: "Login",
  components: { AuthLayout },
  data() {
    return {
      errors: {},
      form: {
        email: "",
        password: "",
        device_name: "windows 10"
      }
    };
  },
  mounted() {
    this.$nextTick(() => {
      const player = document.querySelector("lottie-player");
      player.load(securityAnimation);
    });
  },
  methods: {
    ...mapActions("auth", {
      signIn: "login"
    }),
    async login() {
      this.errors = {};
      try {
        await this.signIn(this.form);
        this.$router.push("/dashboard");
      } catch (e) {
        console.log(e);
        this.errors = e.errors;
      }
    },
    goToLogin() {
      this.$router.push("/auth/login");
    },
    hasError(field) {
      return this.errors[field];
    },
    getError(field) {
      return this.errors[field][0];
    }
  }
});
</script>

<template>
  <AuthLayout>
    <template #leftContent>
      <div class="intro-x w-full h-full">
        <img
          src="/icons/illustrations/forgot-password-rafiki.svg"
          class="inline w-full h-full"
        >
      </div>
    </template>
    <template #rightContent>
      <h2
        class="intro-x font-bold text-2xl xl:text-3xl text-center xl:text-left"
      >
        Reset Password
      </h2>
      <div class="intro-x mt-2 text-gray-500 xl:hidden text-center">
        A few more clicks to sign in to your account. Manage all your e-commerce
        accounts in one place
      </div>
      <form
        method="POST"
        @submit.prevent="login"
      >
        <div class="intro-x mt-8">
          <label :class="{ 'has-error': hasError('email') }">
            <input
              v-model="form.email"
              class="intro-x login__input input input--lg border border-gray-300 block"
              placeholder="Email"
              type="text"
            >
            <span
              v-if="hasError('email')"
              class="pristine-error text-theme-6 mt-2"
            >
              {{ getError("email") }}
            </span>
          </label>
        </div>
        <div class="intro-x mt-5 xl:mt-8 text-center xl:text-left">
          <button
            type="submit"
            class="button button--lg w-full xl:w-52 text-white bg-theme-1 xl:mr-3 align-top"
          >
            Send Password Reset Link
          </button>
          <button
            class="button button--lg w-full xl:w-32 text-gray-700 border border-gray-300 dark:border-dark-5 dark:text-gray-300 mt-3 xl:mt-0 align-top"
            @click="goToLogin"
          >
            Login
          </button>
        </div>
      </form>
    </template>
  </AuthLayout>
</template>
